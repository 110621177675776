<template>
  <CRow class>
    <CCol col="12">
      <CCard>
        <CCardBody>
          <ReceivingAddressComponent
            :items="items"
            :fields="fields"
            :limit="limit"
            :activePage="activePage"
            :fetchReceivingAddress="fetchReceivingAddress"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ReceivingAddressComponent from '@/components/ReceivingAddress/ReceivingAddressComponent';
import endpoints from '@/constants/endpoints';
import { mapState, mapActions } from 'vuex';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'ReceivingAddress',
  components: { ReceivingAddressComponent },
  data() {
    return {
      items: [],
      fields: [
        {
          key: '#',
          label: this.$t('RECEIVING_ADDRESS_PAGE_LABEL_NUMBER'),
        },
        {
          key: 'currency_symbol',
          label: this.$t('RECEIVING_ADDRESS_TABLE_COL_PLATFORM'),
        },
        {
          key: 'wallet_address',
          label: this.$t('RECEIVING_ADDRESS_TABLE_COL_ADDRESS'),
        },
        {
          key: 'actived_flg',
          label: this.$t('RECEIVING_ADDRESS_TABLE_COL_STATUS'),
        },
        {
          key: 'description',
          label: this.$t('RECEIVING_ADDRESS_TABLE_COL_MEMO'),
          _style: 'min-width: 400px',
        },
        {
          key: 'action',
          label: '',
        },
      ],
      total: 0,
      limit: 5,
      activePage: 1,
      pageInput: 1,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    await this.fetchReceivingAddress();
  },
  methods: {
    async fetchReceivingAddress(data) {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          currency_symbol: data ? data : '',
        };
        const res = await this.$http.get(endpoints.getListReceivingAddress, { params });
        this.items = res.data || [];
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title:  this.$t('RECEIVING_ADDRESS_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    async onChangePage(index) {
      this.activePage = index;
      this.fetchReceivingAddress();
    },
  },
};
</script>

<style>
</style>
