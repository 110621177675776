<template>
  <div class="table-receiving-address">
    <div class="label">
      <label>{{$t('RECEIVING_ADDRESS_COMPONENT_TITLE')}}</label>
      <CButton v-if="isAllowedAddingAddress" @click="createAddress" class="btn btn-create-address">+ {{$t('RECEIVING_ADDRESS_COMPONENT_BUTTON_ADD')}}</CButton>
    </div>
    <div class="search-receiving">
      <CSelect
        :label="this.$t('RECEIVING_ADDRESS_COMPONENT_SEARCH_PLATFORM_LABEL')"
        :options="optionsPlatforms"
        :value.sync="searchPlatform"
      />
      <CButton @click="search">{{$t('RECEIVING_ADDRESS_COMPONENT_BUTTON_SEARCH')}}</CButton>
    </div>
    <CCard>
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{index + 1 + (activePage-1)*limit}}</td>
        </template>
        <template #actived_flg="{ item }">
          <td>
            <div class="receiving-address-status">
              <label class="btn-switch">
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="item.actived_flg"
                  @change="handleStatus(item)"
                  :disabled="!isAllowedUpdatingAddress"
                />
                <span class="check-silder"></span>
              </label>
            </div>
          </td>
        </template>
        <template #description="{item}">
          <td
            v-c-tooltip="{
              content: item.description ? item.description : $t('RECEIVING_ADDRESS_COMPONENT_MEMO_TEXT_NO_CONTENT')
            }"
          >
            {{item.description ? sliceDesctiption(item.description) : '-'}}
          </td>
        </template>
        <template #action="{item}">
          <td>
            <CButton v-if="isAllowedUpdatingAddress" @click="edit(item.id)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { getCryptoPlatformTypes } from '@/enums';
import stringHelper from '@/utils/string-helper';

export default {
  name: 'ReceivingAddressComponent',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 999,
    },
    fetchReceivingAddress: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {
      optionsPlatforms: [],
      searchPlatform: '',
      isAllowedUpdatingAddress: false,
      isAllowedAddingAddress: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();
    this.optionsPlatforms = getCryptoPlatformTypes();
    this.optionsPlatforms.unshift({label: this.$t('RECEIVING_ADDRESS_COMPONENT_PLATFORM_OPTION_LABEL_ALL'), value: 'all'});
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_UPDATE_RECEIVING_ADDRESS') {
            this.isAllowedUpdatingAddress = true;
          }

          if (item.name === 'MEMBERSHIP_ADD_RECEIVING_ADDRESS') {
            this.isAllowedAddingAddress = true;
          }
        });
      }
    },
    createAddress() {
      return this.$router.push('receiving-address/create');
    },
    async handleStatus(data) {
      const status = data.actived_flg;
      const msg = status ? this.$t('RECEIVING_ADDRESS_COMPONENT_NOTIFY_ACTIVED_SUCCESS_MESSAGE') : this.$t('RECEIVING_ADDRESS_COMPONENT_NOTIFY_DEACTIVED_SUCCESS_MESSAGE');
      const name = data.currency_symbol;
      const searchPlatform = this.searchPlatform != 'all' ? this.searchPlatform : '';
      try {
        if (status) {
          await this.$http.put(endpoints.activeReceivingAddress(data.id));
        } else {
          await this.$http.put(endpoints.deactiveReceivingAddress(data.id));
        }
        await this.fetchReceivingAddress(searchPlatform);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('RECEIVING_ADDRESS_NOTIFY_SUCCESS_TITLE'),
          text: `${name} ${msg}`,
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('RECEIVING_ADDRESS_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    async search(){
      try {
        if (this.searchPlatform == '' || this.searchPlatform == 'all'){
          await this.fetchReceivingAddress();
        }
        else {
          await this.fetchReceivingAddress(this.searchPlatform);
        }
      }
      catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('RECEIVING_ADDRESS_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    edit(id){
      return this.$router.push(`receiving-address/${id}/edit`);
    },
    sliceDesctiption(string){
      if (string.length > 50){
        return string.slice(0, 50) + '...';
      }
      return string;
    }
  },
};
</script>

<style lang="scss">
.table-receiving-address {
  .label {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    label {
      color: #657187;
      font-size: 18px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0;
    }
    .btn-create-address {
      display: block;
      background: #3b4b7e;
      border: none;
      border-radius: 32px;
      width: 170px;
      height: 32px;
      padding: 0 30px;
      color: #fff !important;
      margin: 0 0 15px 15px;
    }
  }
  .search-receiving{
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    margin: 30px 0 40px;
    .form-group{
      width: 25%;
      margin: 0 50px 0 0;
      label{
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        color: #3E4B65;
        margin: 0;
      }
      select{
        font-size: 14px;
        font-weight: 300;
        color: #8A93A2;
      }
    }
    button{
      width: 25%;
      height: 35px;
      align-self: flex-end;
      font-size: 14px;
      color: #fff;
      background-color: #3B4B7E;
      border-radius: 35px;
    }
  }
  table{
    tbody{
      tr{
        td{
          font-size: 14px;
          font-weight: 400;
          color: #4D5666;
          .receiving-address-status {
            padding: 0;
            h6 {
              margin: 0;
              line-height: 27px;
            }
            .btn-switch {
              position: relative;
              display: inline-block;
              width: 34px;
              height: 19px;
              .checkbox {
                position: absolute;
                opacity: 0;
                width: 0;
                height: 0;
                &:checked + .check-silder::before {
                  border-color: #009900;
                  transform: translateX(15px);
                }
                &:checked + .check-silder {
                  background: #009900;
                }
              }
              .check-silder {
                position: absolute;
                cursor: pointer;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: #fff;
                border: 1px solid #dadada;
                transition: 0.4s;
                border-radius: 19px;
                &:before {
                  position: absolute;
                  content: '';
                  height: 15px;
                  width: 15px;
                  left: 1px;
                  bottom: 1px;
                  top: 1px;
                  background: #fff;
                  border: 1px solid #dadada;
                  transition: 0.4s;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
