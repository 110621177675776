var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-receiving-address"},[_c('div',{staticClass:"label"},[_c('label',[_vm._v(_vm._s(_vm.$t('RECEIVING_ADDRESS_COMPONENT_TITLE')))]),(_vm.isAllowedAddingAddress)?_c('CButton',{staticClass:"btn btn-create-address",on:{"click":_vm.createAddress}},[_vm._v("+ "+_vm._s(_vm.$t('RECEIVING_ADDRESS_COMPONENT_BUTTON_ADD')))]):_vm._e()],1),_c('div',{staticClass:"search-receiving"},[_c('CSelect',{attrs:{"label":this.$t('RECEIVING_ADDRESS_COMPONENT_SEARCH_PLATFORM_LABEL'),"options":_vm.optionsPlatforms,"value":_vm.searchPlatform},on:{"update:value":function($event){_vm.searchPlatform=$event}}}),_c('CButton',{on:{"click":_vm.search}},[_vm._v(_vm._s(_vm.$t('RECEIVING_ADDRESS_COMPONENT_BUTTON_SEARCH')))])],1),_c('CCard',[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage-1)*_vm.limit))])]}},{key:"actived_flg",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"receiving-address-status"},[_c('label',{staticClass:"btn-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.actived_flg),expression:"item.actived_flg"}],staticClass:"checkbox",attrs:{"type":"checkbox","disabled":!_vm.isAllowedUpdatingAddress},domProps:{"checked":Array.isArray(item.actived_flg)?_vm._i(item.actived_flg,null)>-1:(item.actived_flg)},on:{"change":[function($event){var $$a=item.actived_flg,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "actived_flg", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "actived_flg", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "actived_flg", $$c)}},function($event){return _vm.handleStatus(item)}]}}),_c('span',{staticClass:"check-silder"})])])])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: item.description ? item.description : _vm.$t('RECEIVING_ADDRESS_COMPONENT_MEMO_TEXT_NO_CONTENT')
          }),expression:"{\n            content: item.description ? item.description : $t('RECEIVING_ADDRESS_COMPONENT_MEMO_TEXT_NO_CONTENT')\n          }"}]},[_vm._v(" "+_vm._s(item.description ? _vm.sliceDesctiption(item.description) : '-')+" ")])]}},{key:"action",fn:function(ref){
          var item = ref.item;
return [_c('td',[(_vm.isAllowedUpdatingAddress)?_c('CButton',{on:{"click":function($event){return _vm.edit(item.id)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }